import Image from 'next/image'
import React from 'react'
import { User } from "@nextui-org/react";
import Paragraph from '@/custom/Paragraph'
import Heading from '@/custom/Heading'

const Community = () => {
  const users = [
    {
      image: "/mri.jpeg",
      name: "Mahtasham Mridul",
      role: "Student",
      comment: "Since my English was lacking before the PTE, I frequently wondered how I might pass an English proficiency test and fulfill my desire to study abroad. I was able to score 56 overall thanks to Oxford PTE's resources. Now, I'm in Finland. Thank you, Oxford PTE, and lastly, I'd want to encourage all pte candidates to abide by their templates and instructions."
    },
    {
      image: "/mas.jpeg",
      name: "Abdulla Ibnae Masud",
      role: "Student",
      comment: "Just a few hours after my exam, the results were announced. The test score was better than I had anticipated. Without Oxford PTE, I wouldn't be in Australia right now. Gratitude to Oxford PTE for the excellent PTE resources."
    },
    {
      image: "/meh.jpeg",
      name: "Mehedi Hasan",
      role: "Student",
      comment: "The best PTE website is Oxford PTE. I bought all of the Oxford PTE study materials and used a variety of practice tools. As you can see, I took the exam within a week and received the score I wanted. The resources aid in boosting my self-assurance."
    },
    {
      image: "/za.jpeg",
      name: "Za B Ed",
      role: "Student",
      comment: "when I first took the PTE exam in my life. I was exposed to numerous templates and guidelines, but they did not aid me in passing my exam. After contacting Oxford PTE, their templates and PDF files helped me achieve an overall score of 75 on the PTE."
    },
    {
      image: "/sho.jpeg",
      name: "Abu Saleh Sohan",
      role: "Student",
      comment: "In addition to study materials, Oxford PTE taught me time management techniques that were crucial for boosting my confidence in my mock test and ultimately helping me achieve higher marks in my exam. With the aid of Oxford PTE advice and resource materials, I achieved a total score of 67."
    },
    {
      image: "/par.jpeg",
      name: "Partho Saha",
      role: "Student",
      comment: "The Oxford PTE is to be commended first; it was a breath of fresh air for me. Oxford PTE has made it more easy to do and much simpler. Very fast, I received my results, and the first time, I received the score I needed."
    },
  ]
  return (
    <div className='prose max-w-none'>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center">
        <Heading>
          Loved by people across the globe
        </Heading>
        <Paragraph className='text-ox-light-gray text-center'>A wall full of love and support from our community</Paragraph>
        <div className='flex flex-wrap gap-4 mt-10 items-start justify-center'>
          {users?.map(user => {
            return (
              <div key={user.name} className='border border-gray-300 dark:text-ox-dark-text dark:border-gray-50/20 dark:bg-ox-black rounded-xl p-5 w-full lg:w-1/3'>
                <User
                  name={user.name}
                  description={user.role}
                  avatarProps={{
                    src: `${user.image}`
                  }}
                />
                <p className='m-0 text-sm text-ox-black dark:text-ox-dark-text mt-4'>{user.comment}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Community