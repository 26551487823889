const VideoBanner = ({video}) =>
  <video
    autoPlay
    loop
    muted
    className="relative w-full h-full object-cover m-0 p-0"
  >
    <source
      src={video}
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>

export default VideoBanner;
