import React from 'react';
import { FaBookReader, FaHeadphonesAlt } from 'react-icons/fa';
import { RiSpeakFill } from 'react-icons/ri';
import { MdKeyboardHide } from 'react-icons/md';
import { Button } from '@nextui-org/react';
import Link from 'next/link'
import Reveal from './Reveal';
import Paragraph from '@/custom/Paragraph'
import Heading from '@/custom/Heading'


const Guide = () => {
  return (
    <div className=' prose max-w-none'>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center w-full flex items-center justify-center">
          <Reveal >
            <Heading>
              Study Guide
            </Heading>
            <Paragraph>
              Succeed by following our guidelines.
            </Paragraph>
          </Reveal>
        </div>
        <div className='flex flex-wrap gap-4 mt-10 items-start justify-center'>
          <Link href='/guide/speaking' className='border border-gray-300 dark:text-ox-dark-text dark:border-gray-50/20 dark:bg-ox-black rounded-xl p-5 w-full md:w-1/3 lg:w-1/5'>
            <div className='drop'>
              <div className="relative flex items-center justify-center flex-col text-center p-5">
                <RiSpeakFill size={45} className="text-white bg-gradient-to-r from-ox-blue to-blue-700 p-2 rounded-full" />
                <div className="flex flex-col items-center justify-center">
                  <Reveal>
                    <h3 className="m-0 py-2 text-ox-blue">
                      Speaking Guide
                    </h3>
                    <p className="text-sm text-ox-text-light dark:text-ox-dark-text px-3">
                      Read and follow all our speaking exam guidelines.
                    </p>
                    <Button radius="full" size="sm" className='capitalize text-sm dark:bg-background text-ox-blue !shadow-none'>Read More</Button>
                  </Reveal>
                </div>
              </div>
            </div>
          </Link>

          <Link href='/guide/writing' className='border border-gray-300 dark:text-ox-dark-text dark:border-gray-50/20 dark:bg-ox-black rounded-xl p-5 w-full md:w-1/3 lg:w-1/5'>
            <div className='drop'>
              <div className="relative flex items-center justify-center flex-col text-center p-5">
                <MdKeyboardHide size={45} className="text-white bg-gradient-to-r from-ox-green to-green-700 p-2 rounded-full" />
                <div className="flex flex-col items-center justify-center">
                  <Reveal>
                    <h3 className="m-0 py-2 text-ox-green">
                      Writing Guide
                    </h3>
                    <p className="text-sm text-ox-text-light dark:text-ox-dark-text px-3">
                      Read and follow all our writing exam guidelines.
                    </p>
                    <Button radius="full" size="sm" className='capitalize text-sm dark:bg-background text-ox-green !shadow-none'>Read More</Button>
                  </Reveal>
                </div>
              </div>
            </div>
          </Link>

          <Link href='/guide/reading' className='border border-gray-300 dark:text-ox-dark-text dark:border-gray-50/20 dark:bg-ox-black rounded-xl p-5 w-full md:w-1/3 lg:w-1/5'>
            <div className='drop'>
              <div className="relative flex items-center justify-center flex-col text-center p-5">
                <FaBookReader size={45} className="text-white bg-gradient-to-r from-ox-purple to-purple-700 p-2 rounded-full" />
                <div className="flex flex-col items-center justify-center">
                  <Reveal>
                    <h3 className="m-0 py-2 text-ox-purple">
                      Reading Guide
                    </h3>
                    <p className="text-sm text-ox-text-light dark:text-ox-dark-text px-3">
                      Read and follow all our reading exam guidelines.
                    </p>
                    <Button radius="full" size="sm" className='capitalize text-sm dark:bg-background text-ox-purple !shadow-none'>Read More</Button>
                  </Reveal>
                </div>
              </div>
            </div>
          </Link>

          <Link href='/guide/listening' className='border border-gray-300 dark:text-ox-dark-text dark:border-gray-50/20 dark:bg-ox-black rounded-xl p-5 w-full md:w-1/3 lg:w-1/5'>
            <div className='drop'>
              <div className="relative flex items-center justify-center flex-col text-center p-5">
                <FaHeadphonesAlt size={45} className="text-white bg-gradient-to-r from-ox-pink to-pink-700 p-2 rounded-full" />
                <div className="flex flex-col items-center justify-center">
                  <Reveal>
                    <h3 className="m-0 py-2 text-ox-pink">
                      Listening Guide
                    </h3>
                    <p className="text-sm text-ox-text-light dark:text-ox-dark-text px-3">
                      Read and follow all our listening exam guidelines.
                    </p>
                    <Button radius="full" size="sm" className='capitalize text-sm dark:bg-background text-ox-pink !shadow-none'>Read More</Button>
                  </Reveal>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Guide;
