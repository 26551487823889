import { Button } from '@nextui-org/react';
import React from 'react';

const Index = ({ children, type, onClick = () => { }, size, color, variant, className = '' }) => (
    <Button onClick={onClick} type={type} size={size} color={color} variant={variant} className={className}>
        {children}
    </Button>
);

export default Index;
