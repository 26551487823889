import { useState } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody
} from '@material-tailwind/react';
import Reveal from './Reveal'
import Heading from '@/custom/Heading'

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? 'rotate-180' : ''
        } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default function Faqs() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className='prose max-w-none'>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="text-center w-full flex items-center justify-center">
            <Reveal >
              <Heading>
                FAQ&apos;s About PTE
              </Heading>
            </Reveal>
          </div>
        </div>
        <div className='mt-10'>
          <Accordion
            open={open === 1}
            icon={<Icon id={1} open={open} />}>
            <AccordionHeader
              className="capitalize text-base text-ox-black dark:text-ox-dark-text"
              onClick={() => handleOpen(1)}
            >
              What is PTE Academic?
            </AccordionHeader>
            <AccordionBody className='text-ox-text-light text-sm leading-6 dark:text-ox-dark-text'>
              An internationally recognized computer-based English language test is
              called the Pearson Test of English Academic (PTE Academic). It offers
              a gauge of a test taker&apos;s linguistic skill to support educational
              institutions and business, government, and professional organizations
              that need a minimum level of academic English language proficiency for
              admissions.
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open === 2}
            icon={<Icon id={2} open={open} />}
          >
            <AccordionHeader
              className="capitalize text-base text-ox-black dark:text-ox-dark-text"
              onClick={() => handleOpen(2)}
            >
              Why PTE is a good choice?
            </AccordionHeader>
            <AccordionBody className='text-ox-text-light text-sm leading-6 dark:text-ox-dark-text'>
              All across the world, PTE Academic is approved for student and
              immigration applications. The test offers many special benefits in
              addition to its objective assessment methodology. Did you know that
              artificial intelligence forces an algorithm, not a human, to score
              every test taker&apos;s performance?
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open === 3}
            icon={<Icon id={3} open={open} />}
          >
            <AccordionHeader
              className="capitalize text-base text-ox-black dark:text-ox-dark-text"
              onClick={() => handleOpen(3)}
            >
              What is the format of the test?
            </AccordionHeader>
            <AccordionBody className='text-ox-text-light text-sm leading-6 dark:text-ox-dark-text'>
              There are three key components of the test that must be performed
              throughout the three-hour testing period: speaking and writing (done
              jointly), listening, and reading. Between the reading and listening
              sections, there is an additional 10-minute break that is optional.
              Twenty various question types, spanning from multiple choice to essay
              writing and information interpretation, will be present during the
              test.
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open === 4}
            icon={<Icon id={4} open={open} />}
          >
            <AccordionHeader
              className="capitalize text-base text-ox-black dark:text-ox-dark-text"
              onClick={() => handleOpen(4)}
            >
              How should I study for the PTE?
            </AccordionHeader>
            <AccordionBody className='text-ox-text-light text-sm leading-6 dark:text-ox-dark-text'>
              To pass the PTE exam, one should start studying with the desired result in mind because preparation differs depending on the result one intends to achieve. Our module-based environment was developed with the intention of making the exam approachable for everyone.
            </AccordionBody>
          </Accordion>
          <Accordion
            open={open === 5}
            icon={<Icon id={5} open={open} />}
          >
            <AccordionHeader
              className="capitalize text-base text-ox-black dark:text-ox-dark-text"
              onClick={() => handleOpen(5)}
            >
              PTE Vs IELTS
            </AccordionHeader>
            <AccordionBody className='text-ox-text-light text-sm leading-6 dark:text-ox-dark-text'>
              The primary distinction between IELTS and PTE is that IELTS gives candidates the option of taking a paper-based or computer-delivered exam, whereas PTE tests are entirely computer-based and graded by a computer. Additionally, the speaking portion of the IELTS exam involves a face-to-face conversation with an examiner, but in the PTE, a candidate must converse with a computer.
            </AccordionBody>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
