import Image from 'next/image'
import Reveal from './Reveal'
import Heading from '@/custom/Heading'

const Payment = () => {
    return (
        <section className="prose max-w-none">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center py-16">
                <div className="text-center w-full flex items-center justify-center">
                    <Reveal >
                        <Heading>
                            Payment Gateways
                        </Heading>
                    </Reveal>
                </div>
                <div className="flex flex-wrap justify-center gap-4 mt-5">
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/bkash.png"
                        alt="bkash"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/nagad.png"
                        alt="nagad"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/visa.png"
                        alt="visa"
                    />
                    <Image
                        width={65}
                        height={65}
                        src="/mastercard.png"
                        className='object-contain m-0 p-0'
                        alt="mastercard"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/american.png"
                        alt="american express"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/brack.png"
                        alt="brac bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/internet.png"
                        alt="internet banking"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/south.png"
                        alt="southeast bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/rocket.png"
                        alt="rocket"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/trust.png"
                        alt="trust bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/ucb.png"
                        alt="ucb"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/union.png"
                        alt="union pay"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/islami.png"
                        alt="islami bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/ipay.png"
                        alt="ipay"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/dutch.png"
                        alt="dutch bangla"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/city.png"
                        alt="city bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/asia.png"
                        alt="bank asia"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/eastern.png"
                        alt="eastern bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/mkash.png"
                        alt="mkash"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/ok.png"
                        alt="ok wallet"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/premier.png"
                        alt="premier bank"
                    />
                    <Image
                        width={65}
                        height={65}
                        className='object-contain m-0 p-0'
                        src="/upay.png"
                        alt="upay digital taka"
                    />
                </div>
            </div>
        </section>
    )
}
export default Payment