import Hero from '@/components/Hero';
import Contact from '@/components/Contact';
import Guide from '@/components/Guide';
import Faqs from '@/components/Faqs';
import Layout from '@/layout'
import Newsletter from '@/components/Newsletter';
import Community from '@/components/Community';
import Courses from '@/components/Courses';
import Payment from '@/components/Payment'

export default function Home() {
  return (
    <Layout description="The most well-known provider of PTE materials worldwide, Oxford PTE provides live classes as well as the best study materials.">
      <Hero />
      <Guide />
      {/* <Courses /> */}
      <Faqs />
      <Contact />
      <Community />
      <Payment />
      <Newsletter />
    </Layout>
  );
}
