import React from 'react'

const Index = ({ children, className }) => {
    return (
        <div className='prose mx-none'>
            <h2 className={`${className} text-ox-text dark:text-ox-dark-text p-0 m-0`}>
                {children}
            </h2>
        </div>
    )
}

export default Index