import React from 'react'
import Reveal from './Reveal';
import Paragraph from '@/custom/Paragraph'
import Heading from '@/custom/Heading'
import dynamic from 'next/dynamic';
import CardComponent from './CardComponent'
import { Tabs, Tab } from "@nextui-org/react";
import { v4 as uuidv4 } from 'uuid';

const Courses = () => {
  const uniqueId = uuidv4();

  const CARDS_DETAILS = [
    {

      Speaking: [
        {
          id: uniqueId,
          price: 2.5,
          course_name: "read aloud",
          video_url: "/readaloud.mp4",
          quiz_questions: [
            'What is read aloud?',
            'What will be the speed of read aloud?',
            'How to score read aloud?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 3,
          course_name: "repeat sentence",
          video_url: "/repeatsentence.mp4",
          quiz_questions: [
            'What is repeat sentence?',
            'What will be the speed of repeat sentence?',
            'How to score repeat sentence?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1.5,
          course_name: "describe image",
          video_url: "/describeimage.mp4",
          quiz_questions: [
            'What is describe image?',
            'What will be the speed of describe image?',
            'How to score describe image?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1.5,
          course_name: "re-tell lecture",
          video_url: "/readaloud.mp4",
          quiz_questions: [
            'What is re-tell lecture?',
            'What will be the speed of re-tell lecture?',
            'How to score re-tell lecture?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1,
          course_name: "answer short questions",
          video_url: "/readaloud.mp4",
          quiz_questions: [
            'What is answer short questions?',
            'What will be the speed of answer short questions?',
            'How to score answer short questions?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        }]
    },
    {
      Writing: [
        {
          id: uniqueId,
          price: 2,
          course_name: "Summarize Written Text",
          video_url: "/swt.mp4",
          quiz_questions: [
            'What is Summarize Written Text?',
            'What will be the speed of Summarize Written Text?',
            'How to score Summarize Written Text?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 2.5,
          course_name: "Essay",
          video_url: "/essay.mp4",
          quiz_questions: [
            'What is Essay?',
            'What will be the speed of Essay?',
            'How to score Essay?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        }
      ]
    },
    {
      Reading: [
        {
          id: uniqueId,
          price: 5,
          course_name: "Reading & Writing: Fill in the Blanks",
          video_url: "/rwfb.mp4",
          quiz_questions: [
            'What is Reading & Writing: Fill in the Blanks?',
            'What will be the speed of Reading & Writing: Fill in the Blanks?',
            'How to score Reading & Writing: Fill in the Blanks?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1,
          course_name: "Multiple Choice, Multiple Answer",
          video_url: "/mcma.mp4",
          quiz_questions: [
            'What is Multiple Choice, Multiple Answer?',
            'What will be the speed of Multiple Choice, Multiple Answer?',
            'How to score Multiple Choice, Multiple Answer?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 4,
          course_name: "Re-order Paragraphs",
          video_url: "/rop.mp4",
          quiz_questions: [
            'What is Re-order Paragraphs?',
            'What will be the speed of Re-order Paragraphs?',
            'How to score Re-order Paragraphs?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 5,
          course_name: "Fill in the Blanks",
          video_url: "/fib.mp4",
          quiz_questions: [
            'What is Fill in the Blanks?',
            'What will be the speed of Fill in the Blanks?',
            'How to score Fill in the Blanks?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1,
          course_name: "Multiple Choice, Single Answer",
          video_url: "/mcsa.mp4",
          quiz_questions: [
            'What is Multiple Choice, Single Answer?',
            'What will be the speed of Multiple Choice, Single Answer?',
            'How to score Multiple Choice, Single Answer?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
      ]
    },
    {
      Listening: [
        {
          id: uniqueId,
          price: 4,
          course_name: "Summarize Spoken Text",
          video_url: "/sst.mp4",
          quiz_questions: [
            'What is Summarize Spoken Text?',
            'What will be the speed of Summarize Spoken Text?',
            'How to score Summarize Spoken Text?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1,
          course_name: "Multiple Choice, Multiple Answers",
          video_url: "/lmcma.mp4",
          quiz_questions: [
            'What is Multiple Choice, Multiple Answer?',
            'What will be the speed of Multiple Choice, Multiple Answer?',
            'How to score Multiple Choice, Multiple Answer?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 2,
          course_name: "Fill in the Blanks",
          video_url: "/lfib.mp4",
          quiz_questions: [
            'What is Fill in the Blanks?',
            'What will be the speed of Fill in the Blanks?',
            'How to score Fill in the Blanks?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1.5,
          course_name: "Highlight Correct Summary",
          video_url: "/hcs.mp4",
          quiz_questions: [
            'What is Highlight Correct Summary?',
            'What will be the speed of Highlight Correct Summary?',
            'How to score Highlight Correct Summary?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 0.5,
          course_name: "Multiple Choice, Single Answer",
          video_url: "/lmcsa.mp4",
          quiz_questions: [
            'What is Multiple Choice, Single Answer?',
            'What will be the speed of Multiple Choice, Single Answer?',
            'How to score Multiple Choice, Single Answer?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 1.5,
          course_name: "Select Missing Word",
          video_url: "/lmcsa.mp4",
          quiz_questions: [
            'What is Select Missing Word?',
            'What will be the speed of Select Missing Word?',
            'How to score Select Missing Word?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 0.5,
          course_name: "Highlight Incorrect Words",
          video_url: "/lmcsa.mp4",
          quiz_questions: [
            'What is Highlight Incorrect Words?',
            'What will be the speed of Highlight Incorrect Words?',
            'How to score Highlight Incorrect Words?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
        {
          id: uniqueId,
          price: 5,
          course_name: "Write From Dictation",
          video_url: "/lmcsa.mp4",
          quiz_questions: [
            'What is Write From Dictation?',
            'What will be the speed of Write From Dictation?',
            'How to score Write From Dictation?'
          ],
          course_includes: {
            video_length: '4 hours on-demand video',
            downloadable_resources: '12 downloadable resources',
            accessability: 'Access on mobile and TV'
          },
          requirements: ['Basic English Knowledge', 'Basic Knowledge of pte'],
          description: {
            what_you_will_learn: ['A real-life break down of the structure and marking criteria of the PTE Academic test.', 'Construction of scores in each module.', 'Questions to skip.', 'Most valuable question types.', 'How silly PTE really is.'],
            course_description: "Only need 50 points for TR and just want to go over the course structure 2 days before your test? Tired of preparing from Youtube with no sense of security about what works and what don’t? Very close to your scores and don’t feel like spending hundreds of dollars on PTE coaching? Have a solid understanding of English language fundamentals but just need to get acquainted with PTE?",
            course_for: [
              'Looking forward to taking their PTE soon.',
              'Students looking to settle in Australia, New Zealand, Canada and many more countries.',
              'VISA 189, Visa 190, VISA 491 seeking students.'
            ]
          }
        },
      ]
    }
  ]
  const [firstCard, secondCard, thirdCard, fourthCard] = CARDS_DETAILS;
  const speaking = firstCard ? firstCard.Speaking : null;
  const writing = secondCard ? secondCard.Writing : null;
  const reading = thirdCard ? thirdCard.Reading : null;
  const listening = fourthCard ? fourthCard.Listening : null;

  return (
    <div className='prose max-w-none'>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center w-full flex items-center justify-center">
          <Reveal >
            <Heading>
              A broad selection of speaking courses
            </Heading>
            <Paragraph>
              Choose from a variety of monthly-updated online video lectures.
            </Paragraph>
          </Reveal>
        </div>
        <div className="w-full flex flex-wrap items-center justify-center gap-4 mt-5 mb-10">
          {
            speaking?.map((x, i) => <CardComponent key={i} item={x} />)}
        </div>
        <div className="text-center w-full flex items-center justify-center">
          <Reveal >
            <Heading>
              A broad selection of writing courses
            </Heading>
            <Paragraph>
              Choose from a variety of monthly-updated online video lectures.
            </Paragraph>
          </Reveal>
        </div>
        <div className="w-full flex flex-wrap items-center justify-center gap-4 mt-5 mb-10">
          {
            writing?.map((x, i) => <CardComponent key={i} item={x} />)}
        </div>
        <div className="text-center w-full flex items-center justify-center">
          <Reveal >
            <Heading>
              A broad selection of reading courses
            </Heading>
            <Paragraph>
              Choose from a variety of monthly-updated online video lectures.
            </Paragraph>
          </Reveal>
        </div>
        <div className="w-full flex flex-wrap items-center justify-center gap-4 mt-5 mb-10">
          {
            reading?.map((x, i) => <CardComponent key={i} item={x} />)}
        </div>
        <div className="text-center w-full flex items-center justify-center">
          <Reveal >
            <Heading>
              A broad selection of listening courses
            </Heading>
            <Paragraph>
              Choose from a variety of monthly-updated online video lectures.
            </Paragraph>
          </Reveal>
        </div>
        <div className="w-full flex flex-wrap items-center justify-center gap-4 mt-5">
          {
            listening?.map((x, i) => <CardComponent key={i} item={x} />)}
        </div>
      </div>
    </div>
  )
}

export default dynamic(() => Promise.resolve(Courses), { ssr: false });
